import { config } from 'config';
import { STATUS_MAP } from 'constants/campaign';
import {
  CURRENT_RELEASE_GROUPS,
  INNENDIENDST_AND_IT_GROUP,
} from 'constants/user';

export function toLoginPage() {
  const ssoUrl = config.get('ssoUrl');
  const appSsoKey = config.get('appSsoKey');
  window.location = `${ssoUrl}/auth/login/${appSsoKey}`;
}

export function isMemberOf(user, targetGroups) {
  const { groups = [] } = user || {};
  if (!groups?.length) {
    return false;
  }
  return targetGroups.some(targetGroup => groups.includes(targetGroup));
}

export function allowedToUpdateCampaign(user, campaignStatus) {
  return isMemberOf(
    user,
    STATUS_MAP[campaignStatus]?.allowedGroupsToUpdateCampaign,
  );
}

export function allowedToUpdateRunningCampaignBooking(user, campaignStatus) {
  return isMemberOf(
    user,
    STATUS_MAP[campaignStatus]?.allowedGroupsToUpdateRunningBooking,
  );
}

export function allowedToCurrentRelease(user) {
  return isMemberOf(user, CURRENT_RELEASE_GROUPS);
}

export function allowedToManipulateCampaign(user) {
  return isMemberOf(user, INNENDIENDST_AND_IT_GROUP);
}
