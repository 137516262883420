import { INNENDIENDST_AND_IT_GROUP, LS_IT_GROUPS } from './user';

export const CAMPAIGN_REVIEW_STATUS = 'review';
export const CAMPAIGN_DRAFT_STATUS = 'draft';
export const CAMPAIGN_LIVE_STATUS = 'live';
export const CAMPAIGN_APPROVED_STATUS = 'approved';
export const CAMPAIGN_ARCHIVED_STATUS = 'archived';
export const CAMPAIGN_PAUSED_STATUS = 'paused';
export const CAMPAIGN_REJECTED_STATUS = 'rejected';

export const ALL_CAMPAIGN_STATUSES = [
  CAMPAIGN_REVIEW_STATUS,
  CAMPAIGN_DRAFT_STATUS,
  CAMPAIGN_APPROVED_STATUS,
  CAMPAIGN_LIVE_STATUS,
  CAMPAIGN_PAUSED_STATUS,
  CAMPAIGN_ARCHIVED_STATUS,
  CAMPAIGN_REJECTED_STATUS,
];

export const STATUS_MAP = {
  [CAMPAIGN_DRAFT_STATUS]: {
    allowedGroupsToUpdateCampaign: INNENDIENDST_AND_IT_GROUP,
    allowedGroupsToUpdateRunningBooking: [],
    allowedStatuses: [
      {
        id: CAMPAIGN_REVIEW_STATUS,
        allowedGroups: INNENDIENDST_AND_IT_GROUP,
        next: 1,
      },
    ],
  },
  [CAMPAIGN_REVIEW_STATUS]: {
    allowedGroupsToUpdateCampaign: LS_IT_GROUPS,
    allowedGroupsToUpdateRunningBooking: [],
    allowedStatuses: [
      {
        id: CAMPAIGN_DRAFT_STATUS,
        allowedGroups: INNENDIENDST_AND_IT_GROUP,
        next: 0,
      },
      {
        id: CAMPAIGN_REJECTED_STATUS,
        allowedGroups: LS_IT_GROUPS,
        next: 0,
      },
      {
        id: CAMPAIGN_APPROVED_STATUS,
        allowedGroups: LS_IT_GROUPS,
        next: 1,
      },
    ],
  },
  [CAMPAIGN_REJECTED_STATUS]: {
    allowedGroupsToUpdateCampaign: [],
    allowedGroupsToUpdateRunningBooking: [],
    allowedStatuses: [
      {
        id: CAMPAIGN_DRAFT_STATUS,
        allowedGroups: LS_IT_GROUPS,
        next: 1,
      },
    ],
  },
  [CAMPAIGN_APPROVED_STATUS]: {
    allowedGroupsToUpdateCampaign: [],
    allowedGroupsToUpdateRunningBooking: [],
    allowedStatuses: [
      {
        id: CAMPAIGN_REVIEW_STATUS,
        allowedGroups: LS_IT_GROUPS,
        next: 0,
      },
    ],
  },
  [CAMPAIGN_LIVE_STATUS]: {
    allowedGroupsToUpdateCampaign: [],
    allowedGroupsToUpdateRunningBooking: LS_IT_GROUPS,
    allowedStatuses: [
      {
        id: CAMPAIGN_PAUSED_STATUS,
        allowedGroups: LS_IT_GROUPS,
        next: 0,
      },
    ],
  },
  [CAMPAIGN_PAUSED_STATUS]: {
    allowedGroupsToUpdateCampaign: [],
    allowedGroupsToUpdateRunningBooking: [],
    allowedStatuses: [
      {
        id: CAMPAIGN_REVIEW_STATUS,
        allowedGroups: LS_IT_GROUPS,
        next: 0,
      },
      {
        id: CAMPAIGN_LIVE_STATUS,
        allowedGroups: LS_IT_GROUPS,
        next: 1,
      },
    ],
  },
  [CAMPAIGN_ARCHIVED_STATUS]: {
    allowedGroupsToUpdateCampaign: [],
    allowedGroupsToUpdateRunningBooking: [],
    allowedStatuses: [],
  },
};
