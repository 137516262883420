import React, { memo, useCallback } from 'react';
import { useRecoilState } from 'recoil';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { ConfirmAtom } from 'atoms/confirm';

const CLICK_BACKDROP_REASON = 'backdropClick';

/**
 * shows Confirm dialog
 */
function ConfirmDialog() {
  const { t } = useTranslation();
  const [confirm, setConfirm] = useRecoilState(ConfirmAtom);

  const cancel = useCallback(
    (_, reason) => {
      if (reason === CLICK_BACKDROP_REASON || !confirm?.reject) return;
      confirm.reject();
      setConfirm(null);
    },
    [setConfirm, confirm],
  );

  const approve = useCallback(() => {
    if (confirm?.resolve) {
      confirm.resolve();
      setConfirm(null);
    }
  }, [setConfirm, confirm]);

  if (!confirm) {
    return null;
  }

  return (
    <Dialog open onClose={cancel} maxWidth="md">
      <DialogTitle>{t('common.labels.confirmAction')}</DialogTitle>
      <DialogContent>
        <Box whiteSpace="wrap" minWidth={240}>
          {t(confirm.message)}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel} variant="outlined">
          {t('common.labels.cancel')}
        </Button>
        <Button onClick={approve} variant="contained" autoFocus>
          {t('common.labels.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(ConfirmDialog);
